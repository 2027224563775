'use client';

import { createContext } from 'react';

export interface ProgressIndicatorContextType {
  isProgressIndicatorVisible: boolean;
  executeWithProgress(callback: () => Promise<void>): Promise<void>;
  executeWithProgress<T>(callback: () => Promise<T>): Promise<T>;
  navigateWithProgress(callback: () => void): void;
  showProgressIndicator(): void;
  hideProgressIndicator(): void;
}

const ProgressIndicatorContext = createContext<ProgressIndicatorContextType>({
  isProgressIndicatorVisible: false,
  executeWithProgress: () => Promise.resolve(),
  navigateWithProgress: () => {},
  showProgressIndicator: () => {},
  hideProgressIndicator: () => {},
});

export default ProgressIndicatorContext;
