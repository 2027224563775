'use client';

import { ReactNode, useMemo, useState } from 'react';
import FilterDrawerContext from './context';

export default function FilterDrawerProvider({ children }: { children: ReactNode }) {
  const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState<boolean>(false);

  const drawerContextValue = useMemo(
    () => ({
      isFilterDrawerVisible,
      openFilterDrawer: () => {
        setIsFilterDrawerVisible(true);
      },
      closeFilterDrawer: () => {
        setIsFilterDrawerVisible(false);
      },
    }),
    [isFilterDrawerVisible],
  );

  return <FilterDrawerContext.Provider value={drawerContextValue}>{children}</FilterDrawerContext.Provider>;
}
