'use client';

import { createContext } from 'react';

interface FilterDrawerContextType {
  isFilterDrawerVisible: boolean;
  openFilterDrawer(): void;
  closeFilterDrawer(): void;
}

const FilterDrawerContext = createContext<FilterDrawerContextType>({
  isFilterDrawerVisible: false,
  openFilterDrawer: () => {},
  closeFilterDrawer: () => {},
});

export default FilterDrawerContext;
