import { useContext } from 'react';
import clsx from 'clsx';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

export default function ProgressIndicator(): JSX.Element {
  const { isProgressIndicatorVisible } = useContext(ProgressIndicatorContext);

  return (
    <div
      className={clsx(
        'fixed inset-x-0 top-0 z-1200 h-1 overflow-hidden bg-salmon-3 [display:none]',
        'after:block after:h-full after:w-full after:animate-[progress-indicator_2s_infinite] after:bg-salmon-1 after:content-[""] data-[visible=true]:block',
      )}
      data-visible={isProgressIndicatorVisible}
    />
  );
}
