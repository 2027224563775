'use client';

import { ReactNode, startTransition, useCallback, useState } from 'react';
import ProgressIndicator from '~/components/furniturechoice/progress-indicator';
import ProgressIndicatorContext from './context';

export default function ProgressIndicatorProvider({ children }: { children: ReactNode }) {
  const [isProgressIndicatorVisible, setIsProgressIndicatorVisible] = useState<boolean>(false);

  const executeWithProgress = useCallback(async <T,>(callback: () => Promise<T>): Promise<T> => {
    setIsProgressIndicatorVisible(true);

    try {
      const result = await callback();
      return result;
    } catch (error) {
      throw error;
    } finally {
      setIsProgressIndicatorVisible(false);
    }
  }, []);

  const navigateWithProgress = useCallback((callback: () => void): void => {
    setIsProgressIndicatorVisible(true);

    startTransition(() => {
      callback();
      setIsProgressIndicatorVisible(false);
    });
  }, []);

  const showProgressIndicator = useCallback(() => {
    setIsProgressIndicatorVisible(true);
  }, []);

  const hideProgressIndicator = useCallback(() => {
    setIsProgressIndicatorVisible(false);
  }, []);

  const progressIndicatorContextValue = {
    isProgressIndicatorVisible,
    executeWithProgress,
    navigateWithProgress,
    showProgressIndicator,
    hideProgressIndicator,
  };

  return (
    <ProgressIndicatorContext.Provider value={progressIndicatorContextValue}>
      <ProgressIndicator />
      {children}
    </ProgressIndicatorContext.Provider>
  );
}
