'use client';

import { PropsWithChildren } from 'react';
import { FilterDrawerProvider } from '~/helpers/contexts/furniturechoice/filter-drawer';
import { ProgressIndicatorProvider } from '~/helpers/contexts/furniturechoice/progress-indicator';

export default function GlobalProviders({ children }: PropsWithChildren): JSX.Element {
  return (
    <ProgressIndicatorProvider>
      <FilterDrawerProvider>{children}</FilterDrawerProvider>
    </ProgressIndicatorProvider>
  );
}
